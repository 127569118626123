import React, { useEffect, useState } from "react";
import useAuth from "@components/hooks/useAuth";
import { navigate } from "gatsby";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import axios from "axios";

const Component = ({ location }) => {
    const { state, isAuthenticated, loading } = useAuth();
    const [userData, setUserData] = useState(undefined);

    // Retornamos a la página de login con el redirect
    useEffect(() => {
        if (!isAuthenticated && !loading) {
            navigate(`/login?redirect=${location?.pathname ?? ""}`);
        } else if (state?.jwt) {
            // Obtenemos información del usuario
            (async () => {
                try {
                    const json = await axios.get("/.netlify/functions/user-me", {
                        ...(state?.jwt && {
                            headers: {
                                Authorization: "Bearer " + state?.jwt,
                            },
                        }),
                    });

                    setUserData(json?.data);
                } catch (err) {
                    console.log(err?.message);
                    setUserData(null);
                }
            })();
        }
    }, [isAuthenticated, location, loading, state]);

    return (
        <Layout>
            <Seo title="Privado" description="" tags="" />

            <section className="py-20">
                <div className="container">
                    {!loading && isAuthenticated && (
                        <pre className="overflow-hidden">{JSON.stringify(state, null, 2)}</pre>
                    )}

                    {userData && <pre>{JSON.stringify(userData, null, 2)}</pre>}
                </div>
            </section>
        </Layout>
    );
};

export default Component;
